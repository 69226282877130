import { WheelCircumference } from "./BleMeterCommon";

// https://elitesrl.zendesk.com/hc/en-us/articles/360001046434-Speed-value-configuration-with-MyETraining-and-third-party-apps
const EliteWheelCircumferenceModifiers = [
  { name: "Real Turbo Muin B+", divisor: 8.8 },
  { name: "Real Turbo Muin", divisor: 8.8 },
  { name: "Real Turbo", divisor: 8.8 },
  { name: "Suito", divisor: 24.8 },
  { name: "Drivo", divisor: 3.43 },
  { name: "Drivo 2", divisor: 3.43 },
  { name: "Direto", divisor: 12.1 },
  { name: "Direto X", divisor: 12.1 },
  { name: "Direto XR", divisor: 12.1 },
  { name: "Zumo", divisor: 12.48 },
];

const EliteRollerCircumferences = [
  { name: "Tuo", circumference: 47 },
  { name: "Real Tour B+", circumference: 47 },
  { name: "Qubo Digital Smart B+", circumference: 47 },
  { name: "Evo", circumference: 47 },
  { name: "Rampa", circumference: 62 },
  { name: "RealAxiom B+", circumference: 62 },
  { name: "Real Emotion B+", circumference: 67 },
  { name: "Arion Digital Smart B+", circumference: 67 },
  { name: "Nero", circumference: 134 },
];

export const getSpeedDivisor = (deviceName: string): number =>
  EliteWheelCircumferenceModifiers.find((o) => deviceName.toLowerCase() === o.name.toLowerCase())
    ?.divisor ?? 1;

export function getWheelCircumference(deviceName: string) {
  const eliteRoller = EliteRollerCircumferences.find(
    (o) => deviceName.toLowerCase() === o.name.toLowerCase(),
  );

  if (eliteRoller! !== undefined) {
    return eliteRoller.circumference;
  }

  return WheelCircumference / getSpeedDivisor(deviceName);
}
