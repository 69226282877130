/* ------------- Initial State ------------- */

export interface ActiveTrainingState {
  isInitialized: boolean;
  isMetricBackup: boolean;
  status: TRAINING_STATUS_TYPES;
  plan: TrainingPlanInterface | null;
  currentSteps: CurrentStepsInterface;
}

/* ------------- Reducers Actions ------------- */

export interface ResetActiveTrainingAction {
  type: string;
}

export interface SetTrainingPlanAction {
  type: string;
  plan: TrainingPlanInterface;
}

export interface SetCurrentStepsAction {
  type: string;
  currentStep: WorkoutIntervalInterface;
  nextStep: WorkoutIntervalInterface;
  currentStepIndex: number;
  currentCourseStep: CourseSegmentStepInterface | null;
  nextCourseStep: CourseSegmentStepInterface | null;
  currentCourseStepIndex: number | null;
}

export interface SetCurrentCourseStepsAction {
  type: string;
  currentCourseStep: CourseSegmentStepInterface | null;
  nextCourseStep: CourseSegmentStepInterface | null;
  currentCourseStepIndex: number | null;
}

export interface ChangeTrainingStatusAction {
  type: string;
  status: TRAINING_STATUS_TYPES;
}

export interface ChangeBackupStatusAction {
  type: string;
  isMetricBackup: boolean;
}

export interface FinishCourseStepAction {
  type: string;
}

/* ------------- State Elements ------------- */

export enum TRAINING_STATUS_TYPES {
  NOT_INITIALIZED = "NOT_INITIALIZED",
  PENDING_START = "PENDING_START",
  PENDING_START_PAUSED = "PENDING_START_PAUSED",
  STARTED = "STARTED",
  PAUSED = "PAUSED",
  FINISHED = "FINISHED",
  FINISHED_AND_SAVED = "FINISHED_AND_SAVED",
}

export interface CurrentStepsInterface {
  currentStep: WorkoutIntervalInterface | null;
  nextStep: WorkoutIntervalInterface | null;
  currentStepIndex: number | null;
  course: CourseInterface | null;
  currentCourseStep: CourseSegmentStepInterface | null;
  nextCourseStep: CourseSegmentStepInterface | null;
  currentCourseStepIndex: number | null;
}

export interface WorkoutIntervalInterface {
  criticalPowerTime: number;
  description: string | null;
  duration: string;
  durationInSeconds: number;
  index: number;
  intervalId: string;
  intervalType: INTERVAL_TYPE;
  powerWindowHigh: number | null;
  powerWindowLow: number;
  shortText: string;

  courseMovementStatus?: COURSE_MOVEMENT_TYPE;
  relativePower?: number;
  setId?: number;
  startTs?: number;
  cadence?: number;
  cadenceWindowHigh?: number;
  cadenceWindowLow?: number;
  rampUp?: [number, number];
  slope?: number;
  visualExperience?: {
    display_rider_metrics: boolean;
    display_course: boolean;
    display_mini_map: boolean;
  };
  courseSegments: null | CourseSegmentStepInterface[];
}

export interface CourseSegmentStepInterface extends WorkoutIntervalInterface {
  start?: number;
  end?: number;
  course?: string;
  distance?: number;
}

export interface CoursePointsInterface {
  grade: number;
  length: number;
  x: number;
  y: number;
  distance: number;
}

export interface CourseSegmentInterface {
  type: number;
  start: number;
  end: number;
  length: number;
  power: number;
  powerWindow: number;
  cadence: number;
  cadenceWindow: number;
}

export interface CourseInterface {
  courseId: string;
  description: string;
  distance: number;
  totalAscend: number;
  totalDescend: number;
  heightDiff: number;
  minGrade: number;
  maxGrade: number;
  points: CoursePointsInterface[];
  segments: CourseSegmentInterface[];
}

export interface WorkoutSetInterface {
  courseName: string;
  description: string;
  duration: string;
  index: number;
  intervals: WorkoutIntervalInterface[];
}

export interface TrainingPlanInterface {
  description: string;
  duration: number;
  intensityFactor: number;
  sets: WorkoutSetInterface[];
  courses?: CourseInterface[];
  steps: WorkoutIntervalInterface[];
  workoutId: string;
  workoutName: string;
  workoutPlanImage: string;
}

export enum COURSE_MOVEMENT_TYPE {
  PENDING = "pending",
  MOVING = "moving",
  FINISHED = "finished",
  STOPPED = "stopped",
}

export enum INTERVAL_TYPE {
  WARM_UP = "warm-up",
  RECOVER = "recover",
  ACTIVE_RECOVERY = "active_recovery",
  POWER = "power_only",
  POWER_SLOPE = "power_and_slope",
  POWER_CADENCE = "power_and_cadence",
  TIZ = "tiz",
  TAZ = "taz",
  TOZ = "toz",
  TAR = "tar",
  AGR = "agr",
  KOM = "kom",
  KOD = "kod",
  SPR = "spr",
  SEGMENT_BASED = "segment_based",
}
