import { CyclingPowerMeasurementParser } from "./CyclingPowerMeasurementParser";
import { BleDeviceBase } from "./BleDeviceBase";
import { SensorBase } from "./SensorBase";
import { getWheelCircumference } from "./WheelCircumference";

export abstract class BlePowerMeterBase extends SensorBase {
  constructor(bleDevice: BleDeviceBase, deviceName: string, userWeight: number) {
    const wheelCircumference = getWheelCircumference(deviceName);

    const parser = new CyclingPowerMeasurementParser(userWeight, wheelCircumference);
    bleDevice.parser = parser;

    super(deviceName, bleDevice);
  }
}
