import { call, delay, put, select } from "redux-saga/effects";
import { connectUserToLiveTraining, updatePersonalStats } from "../../services/firestore";
import { checkBestEfforts } from "../../utils/BestEffortsCalculating";
import { RecordType } from "../../redux/types";
import {
  getGameInfoStrByType,
  stepScoreCalculating,
  GetGameInfoStrByType,
} from "../../utils/GameScoreCalculating";
import { getMetricsSubset, MetricsSubsetInterface } from "../../utils/MetricsCalculating";
import { createAdvancedStepMetric } from "../../utils/Statistics";
import time from "../../utils/Time";
import { StateInterface } from "../reducers";
import MetricsCreators from "../reducers/metricsReducer";
import NotificationsCreators from "../reducers/notificationsReducer";
import {
  getTimeFromWorkoutStart,
  getTimeFromStepStart,
  getTimeFromSegmentStart,
  getTimeFromUserConnection,
} from "../selectors";
import {
  ActiveMemberMetrics,
  ActiveMemberStats,
  AdvancedStepMetricsInterface,
  CurrentCadenceMetricsInterface,
  CurrentSpeedMetricsInterface,
  CurrentLoadValuesInterface,
  CurrentMetricsInterface,
  CurrentPowerMetricsInterface,
  DeviceDataChangedAction,
  SampleInterface,
  SettingsState,
  StreamStatsAction,
  TRAINING_STATUS_TYPES,
  TRAINING_TYPES,
  ACCURACY_STATUS,
  WorkoutIntervalInterface,
  DeviceType,
  IDevicesState,
  ProfileInterface,
  NotificationLocation,
  TypesOfNotification,
  CourseSegmentStepInterface,
  LiveSegmentsHistoryInterface,
  SegmentsMetricHistoryInterface,
  COURSE_MOVEMENT_TYPE,
  PowerSampleInterface,
  PersonalRecordInterface,
  CurrentHeartRateInterface,
} from "../types";

export function* deviceDataChanged({ deviceData, deviceDataSource }: DeviceDataChangedAction) {
  const devicesState: IDevicesState = yield select((state: StateInterface) => state.devices);

  // Determine from which device the speed and cadence data should be used
  // Either SmartTrainer or PowerMeter must be present
  const defaultDevice = (devicesState.smartTrainerDevice != null)
    ? DeviceType.SmartTrainer
    : DeviceType.PowerMeterDevice;

  let powerDataSource = defaultDevice;
  let speedDataSource = defaultDevice;
  let cadenceDataSource = defaultDevice;

  if (devicesState.powerMeterDevice != null) {
    // Override defaultDefault to take Power and Cadence from the PowerMeter
    powerDataSource = DeviceType.PowerMeterDevice;
    cadenceDataSource = DeviceType.PowerMeterDevice;
  }

  if (devicesState.speedCadenceSensorDevice != null) {
      // Override defaultDevice, take the Speed and Cadence from a Speed Cadence sensor
    if (devicesState.cscSupportsSpeed) {
      speedDataSource = DeviceType.SpeedCadenceSensor;
    }
    if (devicesState.cscSupportsCadence) {
      cadenceDataSource = DeviceType.SpeedCadenceSensor;
    }
  }

  const trainingStatus: TRAINING_STATUS_TYPES = yield select(
    (state: StateInterface) => state.activeTraining.status,
  );
  if (trainingStatus === TRAINING_STATUS_TYPES.STARTED) {
    const timeFromWorkoutStart: number = yield select(getTimeFromWorkoutStart);
    const timeFromUserConnection: number = yield select(getTimeFromUserConnection);

    let sample: SampleInterface = {
      c: null,
      p: null,
      s: null,
      b: null,
      h: null,
      tc: null,
      tp: null,
      e: null,
      g: null,
      ts: time.getTime(),
    };

    const cp: number = yield select((state: StateInterface) => state.settings.criticalPower);

    const timeFromStepStart: number = yield select(getTimeFromStepStart);
    const timeFromSegmentStart: number = yield select(getTimeFromSegmentStart);

    const currentStep: WorkoutIntervalInterface = yield select(
      (state: StateInterface) => state.activeTraining.currentSteps.currentStep,
    );

    const currentSegment: CourseSegmentStepInterface | null = yield select(
      (state: StateInterface) => state.activeTraining.currentSteps.currentCourseStep,
    );

    const metricsSubset: MetricsSubsetInterface = yield call(getMetricsSubset, {
      cp: cp,
      isStarted: true,
      step: currentStep,
      timeFromStepStart: timeFromStepStart,
    });

    const metricsSegmentSubset: MetricsSubsetInterface = yield call(getMetricsSubset, {
      cp: cp,
      isStarted: true,
      step: currentSegment,
      timeFromStepStart: timeFromStepStart,
    });

    const isSegmentBasedStep = !!currentStep.courseSegments;
    const currentMetrics: CurrentMetricsInterface = yield select(
      (state: StateInterface) => state.metrics.currentMetrics,
    );

    const currentSegmentMetrics: CurrentMetricsInterface = yield select(
      (state: StateInterface) => state.metrics.currentSegmentMetrics,
    );

    if (deviceData.power != null && powerDataSource === deviceDataSource) {
      const userWeight: number = yield select(
        (state: StateInterface) => state.user.cpxProfile?.weight || 80,
      );

      const metricHistory: CurrentMetricsInterface[] = yield select(
        (state: StateInterface) => state.metrics.stepsMetricHistory,
      );

      const currentSubset = isSegmentBasedStep ? metricsSegmentSubset : metricsSubset;

      const loadValues: CurrentLoadValuesInterface = {
        loadPower: currentSubset.powerLoad,
        loadPowerWindowLow: currentSubset.powerWindowHigh,
        loadPowerWindowHigh: currentSubset.powerWindowLow,
        loadWatts: currentSubset.wattsLoad,
        loadWattsWindowLow: currentSubset.wattsWindowLow,
        loadWattsWindowHigh: currentSubset.wattsWindowHigh,
        loadCadence: currentSubset.cadenceLoad,
        loadCadenceWindowLow: currentSubset.cadenceWindowLow,
        loadCadenceWindowHigh: currentSubset.cadenceWindowHigh,
      };

      const scorePerStep: number = yield call(stepScoreCalculating, {
        type: currentStep.intervalType,
        currentMetrics: currentMetrics,
        userWeight: userWeight,
        isSegmentBasedStep: isSegmentBasedStep,
      });

      const segmentScore: number = yield call(stepScoreCalculating, {
        type: currentSegment?.intervalType,
        currentMetrics: currentSegmentMetrics,
        userWeight: userWeight,
        isSegmentBasedStep: isSegmentBasedStep,
      });

      const scoreForPreviousSteps =
        metricHistory.length > 0
          ? metricHistory
              .map((item) => item.score)
              .reduce((totalScore, score) => totalScore + score)
          : 0;
      const totalScore = scoreForPreviousSteps + scorePerStep;

      const powerStatus =
        deviceData.power < metricsSubset.wattsLoad - metricsSubset.wattsWindowLow
          ? ACCURACY_STATUS.LOW
          : deviceData.power > metricsSubset.wattsLoad + metricsSubset.wattsWindowHigh
          ? ACCURACY_STATUS.HIGH
          : ACCURACY_STATUS.IN_ZONE;

      const powerStatusAvg =
        currentMetrics.power_avg < metricsSubset.wattsLoad - metricsSubset.wattsWindowLow
          ? ACCURACY_STATUS.LOW
          : deviceData.power > metricsSubset.wattsLoad + metricsSubset.wattsWindowHigh
          ? ACCURACY_STATUS.HIGH
          : ACCURACY_STATUS.IN_ZONE;

      const powerSamples: PowerSampleInterface[] = yield select(
        (state: StateInterface) => state.metrics.powerSamples,
      );
      const personalRecords: PersonalRecordInterface[] = yield select(
        (state: StateInterface) => state.user.cpxProfile?.personalRecords || [],
      );
      const newRecords: RecordType = yield call(checkBestEfforts, {
        powerSamples: powerSamples,
        timeFromWorkoutStart: timeFromWorkoutStart,
        personalRecords: personalRecords,
      });

      const currentPowerMetrics: CurrentPowerMetricsInterface = {
        power: deviceData.power,
        timeFromUserConnection: timeFromUserConnection,
        timeFromWorkoutStart: timeFromWorkoutStart,
        timeFromStepStart: timeFromStepStart,
        timeFromSegmentStart: timeFromSegmentStart,
        currentStepScore: scorePerStep,
        currentSegmentScore: segmentScore,
        totalScore: totalScore,
        powerStatus: powerStatus,
        powerStatusAvg: powerStatusAvg,
        newRecords: newRecords,
      };

      sample.p = deviceData.power;
      sample.tp = metricsSubset.wattsLoad;

      yield put(MetricsCreators.setCurrentPowerMetrics(currentPowerMetrics));
      yield put(MetricsCreators.setCurrentLoadValues(loadValues));
    }

    // cpxp files require that every speed value be paired with a grade value
    // apply a grade value to every sample to be safe
    const grade: number = yield select((state: StateInterface) => state.devices.grade);
    sample.g = grade;

    // cpxp files require every cadence value be paired with an elevation value
    // apply an elevation value to every sample to be safe
    // this sample elevation will be overriden below when deviceData has speed value
    const elevation: number = yield select(
      (state: StateInterface) => state.metrics.workoutMetrics.elevation,
    );
    sample.e = elevation;

    if (deviceData.cadence != null && cadenceDataSource === deviceDataSource) {
      sample.c = deviceData.cadence;
      sample.tc = metricsSubset.cadenceLoad;    

      const cadenceStatus =
        deviceData.cadence < metricsSubset.cadenceLoad - metricsSubset.cadenceWindowLow
          ? ACCURACY_STATUS.LOW
          : deviceData.cadence > metricsSubset.cadenceLoad + metricsSubset.cadenceWindowHigh
          ? ACCURACY_STATUS.HIGH
          : ACCURACY_STATUS.IN_ZONE;

      const cadenceStatusAvg =
        currentMetrics.cadence_avg < metricsSubset.cadenceLoad - metricsSubset.cadenceWindowLow
          ? ACCURACY_STATUS.LOW
          : deviceData.cadence > metricsSubset.cadenceLoad + metricsSubset.cadenceWindowHigh
          ? ACCURACY_STATUS.HIGH
          : ACCURACY_STATUS.IN_ZONE;

      const currentCadenceMetrics: CurrentCadenceMetricsInterface = {
        cadence: deviceData.cadence,
        cadenceStatus: cadenceStatus,
        cadenceStatusAvg: cadenceStatusAvg,
      };
      yield put(MetricsCreators.setCurrentCadenceMetrics(currentCadenceMetrics));
    }

    if (deviceData.speed != null && speedDataSource === deviceDataSource) {
      sample.s = deviceData.speed;

      const currentSpeedMetrics: CurrentSpeedMetricsInterface = {
        speed: deviceData.speed,
        timeFromWorkoutStart: timeFromWorkoutStart,
        timeFromUserConnection: timeFromUserConnection,
        courseMovementStatus: yield select(
          (state: StateInterface) =>
            state.activeTraining.currentSteps.currentStep?.courseMovementStatus || null,
        ),
      };
      const prevDistance: number = yield select(
        (state: StateInterface) => state.metrics.workoutMetrics.distance,
      );
      yield put(MetricsCreators.setCurrentSpeedMetrics(currentSpeedMetrics));
      const nextDistance: number = yield select(
        (state: StateInterface) => state.metrics.workoutMetrics.distance,
      );
      if (nextDistance > prevDistance + 0.001) {
        const dDistanceInMeter = (nextDistance - prevDistance) * 1000;

        const newElevation = elevation + (grade / 100) * dDistanceInMeter;
        sample.e = newElevation;
        yield put(MetricsCreators.setElevation(newElevation)); 
      }
    }

    if (deviceData.powerBalance != null) {
      sample.b = deviceData.powerBalance;
    }

    if (deviceData.heartRate != null) {
      sample.h = deviceData.heartRate;
      const currentHeartRateMetrics: CurrentHeartRateInterface = {
        heartRate: deviceData.heartRate,
      };
      yield put(MetricsCreators.setCurrentHeartRateMetrics(currentHeartRateMetrics));
    }

    // Update Sample for Activities POST
    yield put(MetricsCreators.setSample(sample));
  }
}

export function* streamStats({ trainingType }: StreamStatsAction) {
  let oldStats = null;

  const profile: ProfileInterface = yield select((state: StateInterface) => state.user.profile);
  const { cpxUserId } = profile;
  const liveTrainingId: string = yield select(
    (state: StateInterface) => state.trainingDetails.detailsInfo?.sessionId,
  );
  const criticalPower: number = yield select(
    (state: StateInterface) => state.settings.criticalPower,
  );
  const cpxNickname: string = yield select(
    (state: StateInterface) => state.user.cpxProfile?.cpxNickname,
  );

  while (trainingType === TRAINING_TYPES.LIVE_SESSION && cpxUserId) {
    const trainingStatus: TRAINING_STATUS_TYPES = yield select(
      (state: StateInterface) => state.activeTraining.status,
    );
    if (
      trainingStatus === TRAINING_STATUS_TYPES.PENDING_START ||
      trainingStatus === TRAINING_STATUS_TYPES.PENDING_START_PAUSED ||
      trainingStatus === TRAINING_STATUS_TYPES.NOT_INITIALIZED
    ) {
      yield put(MetricsCreators.resetMetrics());
    }
    const currentCourseStep: CourseSegmentStepInterface | null = yield select(
      (state: StateInterface) => state.activeTraining.currentSteps.currentCourseStep,
    );

    const currentMetrics: CurrentMetricsInterface = yield select(
      (state: StateInterface) => state.metrics.currentMetrics,
    );

    const currentSegmentMetrics: CurrentMetricsInterface = yield select(
      (state: StateInterface) => state.metrics.currentSegmentMetrics,
    );
    const activeMetrics = currentCourseStep !== null ? currentSegmentMetrics : currentMetrics;

    const { cadence_avg, power_avg, score, accuracyStatus } = activeMetrics;

    const cadence: number = yield select(
      (state: StateInterface) => state.devices.data?.cadence || 0,
    );

    const power: number = yield select((state: StateInterface) => state.devices.data?.power || 0);

    const powerBalance: number = yield select(
      (state: StateInterface) => state.devices.data?.powerBalance || 0,
    );

    const heartRate: number = yield select(
      (state: StateInterface) => state.devices.data?.heartRate || 0,
    );

    const leaderboardScoreLine: string = yield select(
      (state: StateInterface) => state.metrics.workoutMetrics.leaderboardScoreLine,
    );
    const currentSegmentIndex: number | null = yield select(
      (state: StateInterface) => state.activeTraining.currentSteps.currentCourseStepIndex,
    );
    const stats: ActiveMemberStats = {
      cadence: cadence,
      powerBalance: powerBalance,
      heartRate: heartRate,
      cadenceAvg: cadence_avg,
      powerAvg: power_avg,
      power: power,
      score: score,
      leaderboardScoreLine: leaderboardScoreLine,
      accuracyStatus: accuracyStatus,
      currentSegmentIndex: currentSegmentIndex,
    };
    const stepsMetricHistory: CurrentMetricsInterface[] = yield select(
      (state: StateInterface) => state.metrics.stepsMetricHistory,
    );
    const segmentsMetricHistory: SegmentsMetricHistoryInterface[] = yield select(
      (state: StateInterface) => state.metrics.segmentsMetricHistory,
    );
    const metrics: ActiveMemberMetrics[] = stepsMetricHistory.map((item, index) => ({
      avgCadencePerStep: item.cadence_avg,
      avgPowerPerStep: item.power_avg,
      stepIndex: index,
      gameAvgScoreLine: item.gameAvgScoreLine,
      score: item.score,
    }));

    const segmentMetrics: LiveSegmentsHistoryInterface[] = segmentsMetricHistory.map((course) => ({
      courseId: course.courseId,
      currentSegmentIndex: currentSegmentIndex,
      segmentsMetric: course.segmentMetricHistory.map((segment, index) => ({
        avgCadencePerStep: segment.cadence_avg,
        avgPowerPerStep: segment.power_avg,
        stepIndex: index,
        gameAvgScoreLine: segment.gameAvgScoreLine,
        score: segment.score,
      })),
    }));

    try {
      if (oldStats?.cadence !== stats.cadence || oldStats?.power !== stats.power) {
        oldStats = stats;
        yield call(updatePersonalStats, cpxUserId, liveTrainingId, stats, metrics, segmentMetrics);
      }
    } catch (error) {
      yield call(connectUserToLiveTraining, liveTrainingId, profile, criticalPower, cpxNickname);
      yield put(
        NotificationsCreators.addNotification({
          location: NotificationLocation.TrainingSaga,
          notificationType: TypesOfNotification.Error,
          description: `${error}`,
        }),
      );
    }

    yield delay(600);
  }
}

export function* calculateMetricHistory() {
  const currentStepMetrics: CurrentMetricsInterface = yield select(
    (state: StateInterface) => state.metrics.currentMetrics,
  );
  const allSamples: SampleInterface[] = yield select(
    (state: StateInterface) => state.metrics.samples,
  );
  const currentStepIndex: number = yield select(
    (state: StateInterface) => state.activeTraining.currentSteps.currentStepIndex,
  );

  const previousStep: WorkoutIntervalInterface = yield select(
    (state: StateInterface) => state.activeTraining.plan?.steps[currentStepIndex - 1],
  );
  const userSettings: SettingsState = yield select((state: StateInterface) => state.settings);
  const userWeight: number = yield select(
    (state: StateInterface) => state.user.cpxProfile?.weight || 80,
  );

  const stepSamples = allSamples.filter((s) => {
    return s.ts >= currentStepMetrics.startTimestamp && s.ts <= time.getTime();
  });

  const advancedStepMetric: AdvancedStepMetricsInterface = yield call(
    createAdvancedStepMetric,
    { ...currentStepMetrics, endTimestamp: time.getTime() },
    stepSamples,
    previousStep,
    userSettings,
  );

  const gameInfo: GetGameInfoStrByType = {
    currentMetrics: currentStepMetrics,
    hasMetrics: true,
    historyMetrics: currentStepMetrics,
    isCurrentStepIndex: false,
    type: previousStep.intervalType,
    userWeight: userWeight,
  };

  const gameAvgScoreLine: string = yield call(getGameInfoStrByType, gameInfo);
  const courseId: string = yield select(
    (state: StateInterface) => state.activeTraining.currentSteps.course?.courseId,
  );
  const courseMovementStatus: COURSE_MOVEMENT_TYPE | undefined = previousStep.courseMovementStatus;

  yield put(
    MetricsCreators.setStepsMetricHistory(
      advancedStepMetric,
      gameAvgScoreLine,
      time.getTime(),
      courseId,
      courseMovementStatus,
    ),
  );
}

export function* calculateSegmentMetricHistory() {
  const currentSegmentMetrics: CurrentMetricsInterface = yield select(
    (state: StateInterface) => state.metrics.currentSegmentMetrics,
  );

  const currentSegmentIndex: number = yield select(
    (state: StateInterface) => state.activeTraining.currentSteps.currentCourseStepIndex,
  );

  const previousSegment: CourseSegmentStepInterface | null = yield select(
    (state: StateInterface) =>
      state.activeTraining.currentSteps.currentStep?.courseSegments?.[currentSegmentIndex - 1],
  );

  const userWeight: number = yield select(
    (state: StateInterface) => state.user.cpxProfile?.weight || 80,
  );

  const gameInfo: GetGameInfoStrByType = {
    currentMetrics: currentSegmentMetrics,
    hasMetrics: true,
    historyMetrics: currentSegmentMetrics,
    isCurrentStepIndex: false,
    type: previousSegment?.intervalType,
    userWeight: userWeight,
  };

  const gameAvgScoreLine: string = yield call(getGameInfoStrByType, gameInfo);
  if (previousSegment?.course) {
    yield put(
      MetricsCreators.setSegmentsMetricHistory(
        previousSegment.course,
        gameAvgScoreLine,
        time.getTime(),
      ),
    );
  }
}
