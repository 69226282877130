import { DebugApi } from "../../api/api";
import { DeviceDataPoint } from "../../redux/types";
import { IParser, GetFlagBit } from "./BleParserHelpers";


export class FtmsDataParser implements IParser {
  userWeight: number;
  isFirstData: boolean;
  speedDivisor: number;

  constructor(userWeight: number, speedDivisor = 1) {
    this.userWeight = userWeight;
    this.isFirstData = true;
    // Needed for Elite FTMS trainers whose speed values require a divisor based on the model type
    // See WheelCircumference.tsx
    this.speedDivisor = speedDivisor;
  }

  GetDataPoint(dataview: DataView) {
    let offset = 0;
    let flagBits = dataview.getUint16(offset, true);
    offset += 2;

    const bitInstantaneousSpeed = !GetFlagBit(0, flagBits);
    const bitAverageSpeed = GetFlagBit(1, flagBits);
    const bitInstantaneousCadence = GetFlagBit(2, flagBits);
    const bitAverageCadence = GetFlagBit(3, flagBits);
    const bitTotalDistance = GetFlagBit(4, flagBits);
    const bitResistanceLevel = GetFlagBit(5, flagBits);
    const bitInstantaneousPower = GetFlagBit(6, flagBits);
    const bitAveragePower = GetFlagBit(7, flagBits);
    const bitExpendedEnergy = GetFlagBit(8, flagBits);
    const bitHeartRate = GetFlagBit(9, flagBits);
    const bitMetabolicEquivalent = GetFlagBit(10, flagBits);
    const bitElapsedTime = GetFlagBit(11, flagBits);
    const bitRemainingTime = GetFlagBit(12, flagBits);

    if (this.isFirstData) {
      this.isFirstData = false;
      let infoText = "FTMS Flags:\r\n";
      infoText += "Instantaneous Speed: " + bitInstantaneousSpeed + "\r\n";
      infoText += "Average Speed: " + bitAverageSpeed + "\r\n";
      infoText += "Instantaneous Cadence: " + bitInstantaneousCadence + "\r\n";
      infoText += "Average Cadence: " + bitAverageCadence + "\r\n";
      infoText += "Total Distance: " + bitTotalDistance + "\r\n";
      infoText += "Resistance Level: " + bitResistanceLevel + "\r\n";
      infoText += "Instantaneous Power: " + bitInstantaneousPower + "\r\n";
      infoText += "Average Power: " + bitAveragePower + "\r\n";
      infoText += "Expended Energy: " + bitExpendedEnergy + "\r\n";
      infoText += "Heart Rate: " + bitHeartRate + "\r\n";
      infoText += "Metabolic Equivalent: " + bitMetabolicEquivalent + "\r\n";
      infoText += "Elapsed Time: " + bitElapsedTime + "\r\n";
      infoText += "Remaining Time: " + bitRemainingTime + "\r\n";
      console.log(infoText);
      DebugApi.log({
        function: "FtmsDataParser GetDataPoint",
        message: infoText,
      });
    }

    //console.log('flags:', flagBits, ' length: ', dataview.byteLength);
    let deviceDataPoint: DeviceDataPoint = {
      cadence: null,
      power: null,
      speed: null,
      powerBalance: null,
      heartRate: null,
    };

    if (bitInstantaneousSpeed) {
      const speed = dataview.getInt16(offset, true);
      offset += 2;
      deviceDataPoint.speed = speed * 0.01 / this.speedDivisor;
    }

    if (bitAverageSpeed) {
      // Ignore
      offset += 2;
    }

    if (bitInstantaneousCadence) {
      const cadence = dataview.getInt16(offset, true);
      offset += 2;
      deviceDataPoint.cadence = cadence * 0.5;
    }

    if (bitAverageCadence) {
      // Ignore
      offset += 2;
    }

    if (bitTotalDistance) {
      // Ignore
      offset += 3;
    }

    if (bitResistanceLevel) {
      // Ignore
      offset += 2;
    }

    if (bitInstantaneousPower) {
      const power = dataview.getInt16(offset, true);
      offset += 2;
      deviceDataPoint.power = power;
    }

    //console.log(deviceDataPoint);
    return deviceDataPoint;
  }
}
