import { call, put, select } from "redux-saga/effects";
import { MetricApi } from "../../api/api";
import { Event } from "../../services/googleAnalyticsTracking";
import { generateCpxPData } from "../../utils/Cpxp";
import { StateInterface } from "../reducers";
import ActiveTrainingCreators from "../reducers/activeTrainingReducer";
import MetricsCreators from "../reducers/metricsReducer";
import NotificationsCreators from "../reducers/notificationsReducer";
import TimerCreators from "../reducers/timerReducer";
import TrainingDetailsCreators from "../reducers/trainingDetailsReducer";
import time from "../../utils/Time";

import {
  AdvancedStepMetricsInterface,
  DetailsInfoInterface,
  IDevicesState,
  USER_TYPES,
  SampleInterface,
  TRAINING_STATUS_TYPES,
  WorkoutIntervalInterface,
  ProfileInterface,
  TRAINING_TYPES,
  NotificationLocation,
  TypesOfNotification,
} from "../types";
import { getCurrentCourseStepIndex } from "../../utils/sagasHelper";
import { AxiosResponse } from "axios";
import { downloadJson, toLocalString } from "../../utils/helpers";

/*-------- Pending start --------*/

export function* pendingStartTraining() {
  yield put(ActiveTrainingCreators.changeTrainingStatus(TRAINING_STATUS_TYPES.PENDING_START));

  const pendingTime: number = yield select(
    (state: StateInterface) => (state.trainingDetails.detailsInfo?.autoStartInSeconds || 60) * 1000,
  );

  const pendingStepStartTimestamp = time.getTime();
  const workoutStartTimestamp = pendingStepStartTimestamp + pendingTime;

  yield put(TimerCreators.setWorkoutTimestamps(workoutStartTimestamp));
  yield put(TimerCreators.setStepTimestamps(pendingStepStartTimestamp));
}

/*-------- Start --------*/

export function* startTraining() {
  const trainingStatus: TRAINING_STATUS_TYPES = yield select(
    (state: StateInterface) => state.activeTraining.status,
  );

  const trainingType: TRAINING_TYPES = yield select(
    (state: StateInterface) => state.trainingDetails.trainingType,
  );

  if (trainingType !== TRAINING_TYPES.LIVE_SESSION) {
    yield put(TimerCreators.timerTick(time.getTime()));
  }

  yield put(TimerCreators.setWorkoutTimestamps(time.getTime() - 1000));
  const currentStepIndex: number = yield select(
    (state: StateInterface) => state.activeTraining.currentSteps.currentStepIndex || 0,
  );
  const workoutSteps: WorkoutIntervalInterface[] = yield select(
    (state: StateInterface) => state.activeTraining.plan?.steps,
  );

  const currentSteps = workoutSteps.slice(currentStepIndex, currentStepIndex + 2);

  const currentCourseStepIndex: number | null = yield select((state: StateInterface) =>
    getCurrentCourseStepIndex({
      currentCourseStepIndex: state.activeTraining.currentSteps.currentCourseStepIndex,
      currentSteps: currentSteps,
    }),
  );

  if (currentCourseStepIndex === 0) {
    yield put(TimerCreators.setSegmentTimestamp(time.getTime()));
  }

  const currentCourseSteps =
    currentCourseStepIndex !== null &&
    currentSteps[0].courseSegments?.slice(currentCourseStepIndex, currentCourseStepIndex + 2);
  const currentCourseStep = currentCourseSteps ? currentCourseSteps[0] : null;
  const nextCourseStep = currentCourseSteps ? currentCourseSteps[1] : null;

  yield put(
    ActiveTrainingCreators.setCurrentSteps(
      currentSteps[0],
      currentSteps[1],
      currentStepIndex,
      currentCourseStep,
      nextCourseStep,
      currentCourseStepIndex,
    ),
  );

  if (trainingStatus === TRAINING_STATUS_TYPES.PENDING_START_PAUSED) {
    yield put(ActiveTrainingCreators.changeTrainingStatus(TRAINING_STATUS_TYPES.PAUSED));
  } else {
    yield put(ActiveTrainingCreators.changeTrainingStatus(TRAINING_STATUS_TYPES.STARTED));
  }

  const firstStepStartTimeStamp = time.getTime();
  yield put(TimerCreators.setStepTimestamps(firstStepStartTimeStamp));
  yield put(MetricsCreators.setFirstStepTimestamp(firstStepStartTimeStamp));
}

/*-------- Next step --------*/

export function* nextStep() {
  const trainingStatus: TRAINING_STATUS_TYPES = yield select(
    (state: StateInterface) => state.activeTraining.status,
  );
  if (
    trainingStatus === TRAINING_STATUS_TYPES.PENDING_START ||
    trainingStatus === TRAINING_STATUS_TYPES.PENDING_START_PAUSED
  ) {
    yield call(startTraining);
  } else {
    const currentStepStartTimeStamp = time.getTime();

    yield put(TimerCreators.timerTick(time.getTime()));
    yield put(TimerCreators.setPauseTimestamp(time.getTime()));
    yield put(TimerCreators.setStepTimestamps(currentStepStartTimeStamp));
    const workoutSteps: WorkoutIntervalInterface[] = yield select(
      (state: StateInterface) => state.activeTraining.plan?.steps,
    );

    const currentStepIndex: number = yield select(
      (state: StateInterface) => state.activeTraining.currentSteps.currentStepIndex || 0,
    );
    const nextStepIndex = currentStepIndex + 1;

    const currentSteps = workoutSteps.slice(nextStepIndex, nextStepIndex + 2);

    const currentCourseStepIndex: number | null = yield select((state: StateInterface) =>
      getCurrentCourseStepIndex({
        currentCourseStepIndex: state.activeTraining.currentSteps.currentCourseStepIndex,
        currentSteps: currentSteps,
      }),
    );

    if (currentCourseStepIndex === 0) {
      yield put(TimerCreators.setSegmentTimestamp(time.getTime()));
    }

    const currentCourseSteps =
      currentCourseStepIndex !== null &&
      currentSteps[0].courseSegments?.slice(currentCourseStepIndex, currentCourseStepIndex + 2);

    const currentCourseStep = currentCourseSteps ? currentCourseSteps[0] : null;

    const nextCourseStep = currentCourseSteps ? currentCourseSteps[1] : null;
    yield put(
      ActiveTrainingCreators.setCurrentSteps(
        currentSteps[0],
        currentSteps[1],
        nextStepIndex,
        currentCourseStep,
        nextCourseStep,
        currentCourseStepIndex,
      ),
    );
  }
}

/*-------- Next Course Step --------*/

export function* nextCourseStep() {
  const currentCourseStepIndex: number | null = yield select(
    (state: StateInterface) => state.activeTraining.currentSteps.currentCourseStepIndex,
  );

  const currentStep: WorkoutIntervalInterface = yield select(
    (state: StateInterface) => state.activeTraining.currentSteps.currentStep,
  );

  const nextCourseStepIndex = currentCourseStepIndex !== null ? currentCourseStepIndex + 1 : null;

  const currentCourseSteps =
    nextCourseStepIndex !== null &&
    currentStep.courseSegments?.slice(nextCourseStepIndex, nextCourseStepIndex + 2);

  const currentCourseStep = currentCourseSteps ? currentCourseSteps[0] : null;

  const nextCourseStep = currentCourseSteps ? currentCourseSteps[1] : null;

  yield put(TimerCreators.setSegmentTimestamp(time.getTime()));

  yield put(
    ActiveTrainingCreators.setCurrentCourseSteps(
      currentCourseStep,
      nextCourseStep,
      nextCourseStepIndex,
    ),
  );
}

/*-------- Pause --------*/

export function* pauseTraining() {
  const trainingStatus: TRAINING_STATUS_TYPES = yield select(
    (state: StateInterface) => state.activeTraining.status,
  );
  const pauseTrainingTimestamp: number = time.getTime();
  yield put(TimerCreators.pauseTimer());
  yield put(TimerCreators.setPauseTimestamp(pauseTrainingTimestamp));
  if (trainingStatus === TRAINING_STATUS_TYPES.PENDING_START) {
    yield put(
      ActiveTrainingCreators.changeTrainingStatus(TRAINING_STATUS_TYPES.PENDING_START_PAUSED),
    );
  } else {
    yield put(ActiveTrainingCreators.changeTrainingStatus(TRAINING_STATUS_TYPES.PAUSED));
  }
}

/*-------- Resume --------*/

export function* resumeTraining() {
  const trainingStatus: TRAINING_STATUS_TYPES = yield select(
    (state: StateInterface) => state.activeTraining.status,
  );

  const pauseTimestamp: number = yield select(
    (state: StateInterface) => state.timer.pauseTimestamp,
  );

  const resumeTimestamp: number = time.getTime();
  const pauseDuration = resumeTimestamp - pauseTimestamp;
  const workoutStartTimestamp: number = yield select(
    (state: StateInterface) => state.timer.workoutStartTimestamp,
  );
  const stepStartTimestamp: number = yield select(
    (state: StateInterface) => state.timer.stepStartTimestamp,
  );

  yield put(TimerCreators.setWorkoutTimestamps(workoutStartTimestamp + pauseDuration));

  yield put(TimerCreators.setStepTimestamps(stepStartTimestamp + pauseDuration));

  if (trainingStatus === TRAINING_STATUS_TYPES.PENDING_START_PAUSED) {
    yield put(ActiveTrainingCreators.changeTrainingStatus(TRAINING_STATUS_TYPES.PENDING_START));
  } else {
    yield put(ActiveTrainingCreators.changeTrainingStatus(TRAINING_STATUS_TYPES.STARTED));
  }
  yield put(TimerCreators.startTimer());
}

/*-------- Finish --------*/

export function* finishTraining() {
  const userType: USER_TYPES = yield select((state: StateInterface) => state.user.userType);
  const isMetricBackup: boolean = yield select(
    (state: StateInterface) => state.activeTraining.isMetricBackup,
  );
  const trainingStatus: TRAINING_STATUS_TYPES = yield select(
    (state: StateInterface) => state.activeTraining.status,
  );

  const user: ProfileInterface = yield select((state: StateInterface) => state.user.profile);
  const session: DetailsInfoInterface = yield select(
    (state: StateInterface) => state.trainingDetails.detailsInfo,
  );
  const workoutId: string = yield select(
    (state: StateInterface) => state.activeTraining.plan?.workoutId,
  );
  const advancedStepsMetrics: AdvancedStepMetricsInterface[] = yield select(
    (state: StateInterface) => state.metrics.advancedStepsMetrics,
  );
  const device: IDevicesState = yield select((state: StateInterface) => state.devices);
  const samples: SampleInterface[] = yield select((state: StateInterface) => state.metrics.samples);

  const cpxPData: ReturnType<typeof generateCpxPData> = yield call(
    generateCpxPData,
    user,
    session,
    workoutId,
    advancedStepsMetrics,
    device,
    samples,
  );

  const cpxPDataJson = JSON.stringify(cpxPData);

  // Save to JSON to the filesystem as a backup
  if (!isMetricBackup) {
    yield put(ActiveTrainingCreators.changeBackupStatus(true));
    const filename = `performance_file__${toLocalString(new Date())}`;
    downloadJson(filename, cpxPDataJson);
  }

  Event("workout", {
    cpx_id: user.cpxUserId,
    wid: workoutId,
    coach_id: session.coachId,
    total_work: cpxPData.TotalWork,
    np: cpxPData.NormalizedPower,
  });

  if (
    userType !== USER_TYPES.USER &&
    trainingStatus !== TRAINING_STATUS_TYPES.FINISHED_AND_SAVED &&
    trainingStatus !== TRAINING_STATUS_TYPES.NOT_INITIALIZED
  ) {
    try {
      yield put(
        ActiveTrainingCreators.changeTrainingStatus(TRAINING_STATUS_TYPES.FINISHED_AND_SAVED),
      );
      const response: AxiosResponse | Error = yield call(
        MetricApi.postFinalMetricsActivities,
        cpxPDataJson,
      );
      if (response instanceof Error) {
        throw response;
      }
      if (response.status !== 200) {
        throw new Error("Your session progress not saved");
      }
    } catch (error) {
      yield put(
        NotificationsCreators.addNotification({
          location: NotificationLocation.TrainingSaga,
          notificationType: TypesOfNotification.Warning,
          description: `Your session progress was not saved. Please contact PowerWatts support`,
        }),
      );
    }
  }

  yield put(TrainingDetailsCreators.resetTrainingDetails());
  yield put(ActiveTrainingCreators.resetActiveTraining());
  yield put(TimerCreators.resetTimer());
  yield put(MetricsCreators.resetMetrics());
}

export function* finishLiveTraining() {
  const userType: USER_TYPES = yield select((state: StateInterface) => state.user.userType);
  const trainingStatus: TRAINING_STATUS_TYPES = yield select(
    (state: StateInterface) => state.activeTraining.status,
  );

  if (
    userType !== USER_TYPES.USER &&
    trainingStatus !== TRAINING_STATUS_TYPES.FINISHED_AND_SAVED &&
    trainingStatus !== TRAINING_STATUS_TYPES.NOT_INITIALIZED
  ) {
    const user: ProfileInterface = yield select((state: StateInterface) => state.user.profile);
    const session: DetailsInfoInterface = yield select(
      (state: StateInterface) => state.trainingDetails.detailsInfo,
    );
    const workoutId: string = yield select(
      (state: StateInterface) => state.activeTraining.plan?.workoutId,
    );
    const advancedStepsMetrics: AdvancedStepMetricsInterface[] = yield select(
      (state: StateInterface) => state.metrics.advancedStepsMetrics,
    );
    const device: IDevicesState = yield select((state: StateInterface) => state.devices);
    const samples: SampleInterface[] = yield select(
      (state: StateInterface) => state.metrics.samples,
    );

    const cpxPData: ReturnType<typeof generateCpxPData> = yield call(
      generateCpxPData,
      user,
      session,
      workoutId,
      advancedStepsMetrics,
      device,
      samples,
    );

    Event("workout", {
      cpx_id: user.cpxUserId,
      wid: workoutId,
      coach_id: session.coachId,
      total_work: cpxPData.TotalWork,
      np: cpxPData.NormalizedPower,
    });

    try {
      yield put(
        ActiveTrainingCreators.changeTrainingStatus(TRAINING_STATUS_TYPES.FINISHED_AND_SAVED),
      );
      yield call(MetricApi.postFinalMetricsActivities, JSON.stringify(cpxPData));
    } catch (error) {
      yield put(
        NotificationsCreators.addNotification({
          location: NotificationLocation.TrainingSaga,
          notificationType: TypesOfNotification.Warning,
          description: `Your session progress was not saved`,
        }),
      );
    }
  }
}
